import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../reducers/sessionSlice';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function CustomAppBar({ title, displayLogin, displayMenu, displayHome }) {
    const navigate = useNavigate();

    const adminBackground = { backgroundColor: '#700000' };
    const userBackground = { backgroundColor: '#0d4f00' };

    //redux
    const session = useSelector((state) => state.session.value);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const returnToHome = () => {
        navigate('/home');
    }

    const naviateToAllMembers = () => {
        navigate('/members');
    };

    const navigateToMembershipVerification = () => {
        navigate('/membership-verification');
    };

    const navigateToClassroomsAndAttendance = () => {
        navigate('/meetings-and-attendance');
    };

    const navigatetoDashboard = () => {
        navigate('/dashboard');
    };

    const navigateToRegistration = () => {
        navigate('/registration-and-payments');
    };

    const navigateToOrganizationSettings = () => {
        navigate('/organization-settings');
    };


    const handleLogin = async () => {

        // Check if the user is logged in
        if (session) {
            // If the user is logged in, log them out
            dispatch(removeToken());
            navigate('/home');
        } else {
            // If the user is not logged in, redirect them to the login page
            navigate('/login');
        }
    };


    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={{ backgroundColor: session === null ? userBackground.backgroundColor : adminBackground.backgroundColor }}>
                    <Toolbar>
                        {displayMenu && <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}

                            onClick={handleClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        }
                        {open && <Menu
                            id="basic-menu"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={navigatetoDashboard}>Dashboard</MenuItem>
                            <MenuItem onClick={naviateToAllMembers}>All Members</MenuItem>
                            <MenuItem onClick={navigateToClassroomsAndAttendance}>Meetings & Attendance</MenuItem>
                            <MenuItem disabled onClick={navigateToMembershipVerification}>Payment Verification</MenuItem>
                            <MenuItem onClick={navigateToRegistration}>Registration & Payments</MenuItem>
                            <MenuItem disabled onClick={navigateToOrganizationSettings}>Organization Settings</MenuItem>
                        </Menu>}
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {title}
                        </Typography>
                        {displayHome && <Button color="inherit" onClick={returnToHome}>Home</Button>}
                        {displayLogin && <Button color="inherit" onClick={handleLogin}>{session ? "Logout" : "Login"}</Button>}
                    </Toolbar>
                </AppBar>
            </Box>
            <br />
            <br />
            <br />
        </div>
    );
}

export default CustomAppBar;
