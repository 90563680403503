import React from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';

function VersionHistory() {
    return (
        <div className="center-text">
            <CustomAppBar title="Software Version History" displayLogin={false} displayMenu={false} displayHome={true} />
            <p>Learn more about the development of this web application. Interested in this software? Contact the developer at </p>
            <br></br>
            <br></br>

            <Grid container spacing={{ xs: 4, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={3}>
                    <Card elevation={5} sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image="/images/version-history/1.1.jpg"
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Version 1.1
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Fixes, Attendance improvements, and new features. This version includes the following features:
                                    <ul>
                                        <li>Password authentication for meetings</li>
                                        <li>Enabled more attendance options for new meetings</li>
                                        <li>Changed insurcance 'POLY' to 'PONY'</li>
                                        <li>Added tooltips for the admin panel</li>
                                        <li>Enabled mobile support</li>
                                        <li>Added Guest Attendance Submission Support</li>
                                        <li>Added Membership Details Lookup Public Page</li>
                                        <li>Migrated Web App to a new Subdomain for the customer</li>
                                    </ul>
                                    <p>Released March 22 2024</p>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card elevation={5} sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image="/images/version-history/1.0.0.jpg"
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Version 1.0
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    The foundation of the web application. This version includes the following features:
                                    <ul>
                                        <li>Admin Membership Handling</li>
                                        <li>Admin Attendance Tracking with Public Interface</li>
                                        <li>Membership Registration & Payment Verification</li>
                                        <li>Dashboard for Administrators</li>
                                        <li>Login Functionality</li>
                                        <li>Error Handling and backend validations</li>
                                        <li>Scalable Database for multiple Organizations</li>
                                    </ul>

                                    <p>Released February 11 2024</p>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3}>

                </Grid>
            </Grid>

        </div>
    );
}

export default VersionHistory;
