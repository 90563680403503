import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import Stack from '@mui/material/Stack';

function getStyles(name, orgName, theme) {
    return {
        fontWeight:
            orgName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AddAttendanceComponent = ({ admin, session, meeting, openAttendDialog, setOpenAttendDialog }) => {

    const [openSnackbar, setSnackbarOpen] = React.useState(false);
    const [openErrorSnackbar, setErrorSnackbarOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [attendanceProofNeeded, setAttendanceProofNeeded] = useState(false);
    const [fullName, setFullName] = useState(''); //this is for the textfield in the dialog
    const [email, setEmail] = useState(''); //this is for the textfield in the dialog
    const [dialogIndex, setDialogIndex] = useState(0);
    const [serverErrorMessage, setServerErrorMessage] = useState('');

    //locked meeting variables
    const [unlocked, setUnlocked] = useState(false);
    const [password, setPassword] = useState('');

    //new flexible attendance system
    const [selectedGuestOrReg, setSelectedGuestOrReg] = useState(false); //if true, they have moved past the selection dialog
    const [isGuest, setIsGuest] = useState(false);

    //this is for the select dropdown
    const names = [
        'Channel Coast',
        'Foothill-Citrus',
        'Los Angeles',
        'Long Beach',
        'Orange County',
        'San Gabriel Valley',
        'South Bay',
        'Other'
    ];
    const theme = useTheme();
    const [orgName, setOrgName] = React.useState([]);

    const handleSelectChange = (event) => {
        const {
            target: { value },
        } = event;
        setOrgName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleBackBtnPress = () => {
        setAttendanceProofNeeded(false);
        setDialogIndex(dialogIndex - 1);
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleAttendanceProofNeeded = () => {
        setAttendanceProofNeeded(true);
    }

    const handleAttendanceProofNotNeeded = () => {
        setAttendanceProofNeeded(false);
    }

    const handleCloseErrorSnackbar = () => {
        setErrorSnackbarOpen(false);
    };

    const handleNextDialog = async () => {


        if (dialogIndex === 0) {
            //check if the name is in the database
            //if it is, then move to the next dialog
            //if not, then display an error message
            if (fullName === '' || !fullName.includes(' ')) {
                setErrorMessage('Please enter your full name.');
                return;
            }

            if (fullName.split(' ').length !== 2) {
                setErrorMessage('Please enter your full name with a space between the first and last name. No suffixes or prefixes.');
                return;
            }

            try {

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/isRegisteredMember`, {
                    params: { first_name: fullName.split(' ')[0], last_name: fullName.split(' ')[1] }
                });

                if (!response.data.found) {
                    if (admin) {
                        setErrorMessage('Name not found in the database. At the moment, admins cannot add attendance for guests. Please contact the dev to add this guest user. Not a guest? Check to see if they are registered.');
                    } else {
                        setErrorMessage('Name not found in the database. Please try again or select Guest if not registered.');
                    }

                    return;
                }
                if (!response.data.isMember) {

                    if (admin) {
                        setErrorMessage('This member is not a registered member for this season.');
                    } else {
                        setErrorMessage('You are not a registered member for this season. If is a mistake, please contact the organization.');
                    }

                    return;
                }

                setDialogIndex(dialogIndex + 1);
                setErrorMessage('');
            }
            catch (error) {
                setErrorMessage('Invalid request. Please enter a full name without additional spaces.');
                console.error(error);
            }

        } else {
            setDialogIndex(dialogIndex + 1);
        }
    }

    const handleBackGuestBtnPress = () => {
        setDialogIndex(dialogIndex - 1);
    }

    const handleNextGuestDialog = () => {

        if (dialogIndex === 0) {
            if (fullName === '' || !fullName.includes(' ')) {
                setErrorMessage('Please enter your full name.');
                return;
            } else if (email === '' || !email.includes('@')) {
                setErrorMessage('Please enter a valid email address.');
                return;
            }
            setErrorMessage('');
            setAttendanceProofNeeded(true);
            setDialogIndex(dialogIndex + 1);
        }
    }

    const handleNewAttendance = async () => {
        let proof = "No";

        if (orgName.length !== 0) {
            proof = orgName.join(',');
        }

        if (admin) {
            try {

                //admin does not need the password for this meeting.
                const headers = {
                    Authorization: `Bearer ${session}`
                };

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/private/attendance`,
                    {
                        action: 'addNewAttendance', full_name: fullName, meeting_id: meeting._id, proof_needed: proof
                    },
                    { headers: headers },
                );

                if (response.data.success) {
                    setSnackbarOpen(true);
                    handleCloseAttendDialog()
                } else {
                    console.log("Something went wrong. Please try again.");
                }

            } catch (error) {
                console.error(error);
                if (error.response.status === 400) {
                    handleCloseAttendDialog();
                    setServerErrorMessage("An error occurred. When submitting the attendance. Please confirm that the member has not been added to the attendance.");
                    setErrorSnackbarOpen(true);
                } else if (error.response.status === 401) {
                    handleCloseAttendDialog();
                    setServerErrorMessage("Unauthorized. Please log out and log back in and try again.");
                    setErrorSnackbarOpen(true);
                }
            }
        } else {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/public/attendance`,
                    { action: 'addNewAttendance', full_name: fullName, meeting_id: meeting._id, proof_needed: proof, password: password }
                );

                if (response.data.success) {
                    handleCloseAttendDialog();
                    setSnackbarOpen(true);
                } else {
                    console.log("Something went wrong. Please try again.");
                }

            } catch (error) {
                console.error(error);

                if (error.response.status === 400) {
                    handleCloseAttendDialog();
                    setErrorSnackbarOpen(true);
                    setServerErrorMessage("Attendance record already exists under this name.");
                } else {
                    handleCloseAttendDialog();
                    setErrorSnackbarOpen(true);
                    setServerErrorMessage("Attendance not added. Please check response: " + error.response.data.error);
                }
            }
        }
    }

    const handleNewAttendanceGuest = async () => {
        let proof = "No";

        if (orgName.length !== 0) {
            proof = orgName.join(',');
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/public/attendance`,
                { action: 'addNewAttendanceAsGuest', full_name: fullName, email: email, meeting_id: meeting._id, proof_needed: proof, password: password }
            );

            if (response.data.success) {
                handleCloseAttendDialog();
                setSnackbarOpen(true);
            } else {
                console.log("Something went wrong. Please try again.");
                handleCloseAttendDialog();
                setServerErrorMessage("Something went wrong. Please try again.");
                setErrorSnackbarOpen(true);
            }

        } catch (error) {
            console.error(error);
            handleCloseAttendDialog();
            setErrorSnackbarOpen(true);
            setServerErrorMessage("Attendance not added. Please check response: " + error.response.data.error);
        }
    }


    const handleUnlockmeeting = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/public/meetings`,
                { action: 'unlockMeeting', meeting_id: meeting._id, password: password }
            );

            if (response.data.success) {
                setUnlocked(true);
            } else {
                console.log("Incorrect password. Please try again.");
                handleCloseAttendDialog();
                setErrorSnackbarOpen(true);
                setServerErrorMessage("Incorrect password. Please try again.")
            }

        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                handleCloseAttendDialog();
                setErrorSnackbarOpen(true);
                setServerErrorMessage("Incorrect password. Please try again.");
            } else {
                handleCloseAttendDialog();
                setErrorSnackbarOpen(true);
                setServerErrorMessage("Internal server error.");
            }
        }
    }

    const handleIsGuestBtnPress = () => {
        setIsGuest(true);
        setSelectedGuestOrReg(true);
    }

    const handleIsRegMemberBtnpress = () => {
        setIsGuest(false);
        setSelectedGuestOrReg(true);
    }

    const handleCloseAttendDialog = () => {
        setOpenAttendDialog(false);
        setErrorMessage('');
        setDialogIndex(0);
        setAttendanceProofNeeded(false);
        setFullName('');
        setPassword('');
        setEmail('');
        setOrgName([]);
        setUnlocked(false);
        setIsGuest(false);
        setSelectedGuestOrReg(false);
    };

    return (
        <div>
            <Dialog
                open={openAttendDialog}
                onClose={handleCloseAttendDialog}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        handleNewAttendance();
                    },
                }}
            >
                {/* Locked meeting update*/}
                {(!unlocked && meeting.password_authenticated) && !admin ?
                    //LOCKED MEETING - NEEDS TO UNLOCK IT
                    <div>
                        <DialogTitle>Meeting Locked</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                This meeting is locked. Please enter the meeting password to attend.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                defaultValue={fullName}
                                required
                                margin="dense"
                                id="password"
                                name="password"
                                label="Password"
                                fullWidth
                                variant="standard"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseAttendDialog}>Cancel</Button>
                            <Button onClick={handleUnlockmeeting}>Unlock</Button>
                        </DialogActions>
                    </div>

                    :
                    // UNLOCKED MEETING && Choose if guest or registered member:


                    <div>
                        {!admin && !selectedGuestOrReg ?
                            // Select if you are a registered member or a guest:
                            <div>
                                <DialogTitle>Please Confirm</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Are you a guest (not a registered member for this org) or a registered member for this season?
                                    </DialogContentText>
                                    <br></br>
                                    <Stack spacing={2} direction="row" sx={{ justifyContent: 'center' }}>
                                        <Button variant="outlined" onClick={handleIsGuestBtnPress}>Guest</Button>
                                        <Button variant="contained" onClick={handleIsRegMemberBtnpress}>Registered Member</Button>
                                    </Stack>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseAttendDialog}>Cancel</Button>
                                </DialogActions>
                            </div>
                            :

                            <div>
                                {!admin && isGuest ?
                                    // This is a guest
                                    <div>
                                        <DialogTitle>{admin ? "Add New Attendance" : "Attend Meeting [Guest]"}</DialogTitle>

                                        {dialogIndex === 0 &&
                                            <DialogContent>
                                                <DialogContentText>

                                                    If you are a guest, then please enter your full name and email address to attend this meeting. Note: Name should not include suffixes or prefixes (ex: Jr., Sr., III, etc.).
                                                </DialogContentText>
                                                <TextField
                                                    autoFocus
                                                    defaultValue={fullName}
                                                    required
                                                    margin="dense"
                                                    id="name"
                                                    name="fullname"
                                                    label="First & Last Name"
                                                    fullWidth
                                                    variant="standard"
                                                    onChange={(e) => setFullName(e.target.value)}
                                                />

                                                <TextField
                                                    defaultValue={email}
                                                    required
                                                    margin="dense"
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    fullWidth
                                                    variant="standard"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />

                                            </DialogContent>
                                        }

                                        {/*Do you need credit?*/}
                                        {dialogIndex === 1 &&
                                            <div>
                                                <DialogContentText style={{ paddingLeft: '30px', paddingRight: '30px' }}>

                                                    {admin ? `Does this member need proof of attendance for this attendance?` : `Do you need proof of attendance for a unit that is not this one?`}
                                                </DialogContentText>
                                                <FormControl style={{ paddingLeft: '30px' }}>
                                                    <FormLabel id="proof-row-radio-buttons-group-label"></FormLabel>
                                                    <RadioGroup
                                                        row
                                                        name="row-radio-buttons-group"
                                                        defaultValue="yes"
                                                    >
                                                        <FormControlLabel value="no" control={<Radio />} onChange={handleAttendanceProofNotNeeded} label="No" />
                                                        <FormControlLabel value="yes" control={<Radio />} onChange={handleAttendanceProofNeeded} label="Yes" />
                                                    </RadioGroup>
                                                </FormControl>

                                                {attendanceProofNeeded && <div style={{ paddingLeft: '30px' }}>
                                                    <p>Select the other organization(s):</p>
                                                    <FormControl sx={{ m: 1, width: 300 }}>
                                                        <InputLabel id="demo-multiple-chip-label">Organizations</InputLabel>
                                                        <Select
                                                            labelId="multiple-chip-label"
                                                            id="multiple-chip"
                                                            multiple
                                                            value={orgName}
                                                            onChange={handleSelectChange}
                                                            input={<OutlinedInput id="select-multiple-chip" label="Organizations" />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => (
                                                                        <Chip key={value} label={value} />
                                                                    ))}
                                                                </Box>
                                                            )}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {names.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                    style={getStyles(name, orgName, theme)}
                                                                >
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>}

                                            </div>}


                                        <p style={{ textAlign: 'center', color: 'red' }}>{errorMessage}</p>
                                        <DialogActions>
                                            {dialogIndex === 0 && <Button onClick={handleCloseAttendDialog}>Cancel</Button>}
                                            {dialogIndex > 0 && <Button onClick={handleBackGuestBtnPress}>Back</Button>}
                                            {dialogIndex < 1 && <Button onClick={handleNextGuestDialog}>Next</Button>}
                                            {dialogIndex === 1 && <Button onClick={handleNewAttendanceGuest}>Submit</Button>}
                                        </DialogActions>
                                    </div>

                                    :

                                    //if registered member or admin, then show this dialog
                                    <div>
                                        <DialogTitle>{admin ? "Add New Attendance" : "Attend Meeting [Registered Member]"}</DialogTitle>

                                        {/* Full name quetion */}
                                        {dialogIndex === 0 &&
                                            <div>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        {admin ? "Enter the member's full name below. Note: You can only add attendance for registered members at the moment. Guests will need to use the public webpage." : "If you are a registered member for this current season, then please enter your full name to attend this meeting. Note: Name should not include suffixes or prefixes (ex: Jr., Sr., III, etc.)."}

                                                    </DialogContentText>
                                                    <TextField
                                                        autoFocus
                                                        defaultValue={fullName}
                                                        required
                                                        margin="dense"
                                                        id="name"
                                                        name="fullname"
                                                        label="First & Last Name"
                                                        fullWidth
                                                        variant="standard"
                                                        onChange={(e) => setFullName(e.target.value)}
                                                    />
                                                </DialogContent>
                                                <p style={{ textAlign: 'center', color: 'red' }}>{errorMessage}</p>
                                            </div>
                                        }

                                        {/*Do you need credit?*/}
                                        {dialogIndex === 1 &&
                                            <div>
                                                <DialogContentText style={{ paddingLeft: '30px', paddingRight: '30px' }}>

                                                    {admin ? `Does this member need proof of attendance for this attendance?` : `Do you need proof of attendance for a unit that is not this one?`}
                                                </DialogContentText>
                                                <FormControl style={{ paddingLeft: '30px' }}>
                                                    <FormLabel id="proof-row-radio-buttons-group-label"></FormLabel>
                                                    <RadioGroup
                                                        row
                                                        name="row-radio-buttons-group"
                                                        defaultValue="no"
                                                    >
                                                        <FormControlLabel value="no" control={<Radio />} onChange={handleAttendanceProofNotNeeded} label="No" />
                                                        <FormControlLabel value="yes" control={<Radio />} onChange={handleAttendanceProofNeeded} label="Yes" />
                                                    </RadioGroup>
                                                </FormControl>

                                                {attendanceProofNeeded && <div style={{ paddingLeft: '30px' }}>
                                                    <p>Select the other organization(s):</p>
                                                    <FormControl sx={{ m: 1, width: 300 }}>
                                                        <InputLabel id="demo-multiple-chip-label">Organizations</InputLabel>
                                                        <Select
                                                            labelId="multiple-chip-label"
                                                            id="multiple-chip"
                                                            multiple
                                                            value={orgName}
                                                            onChange={handleSelectChange}
                                                            input={<OutlinedInput id="select-multiple-chip" label="Organizations" />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => (
                                                                        <Chip key={value} label={value} />
                                                                    ))}
                                                                </Box>
                                                            )}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {names.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                    style={getStyles(name, orgName, theme)}
                                                                >
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>}

                                            </div>}
                                        <DialogActions>
                                            {dialogIndex === 0 && <Button onClick={handleCloseAttendDialog}>Cancel</Button>}
                                            {dialogIndex > 0 && <Button onClick={handleBackBtnPress}>Back</Button>}
                                            {dialogIndex < 1 && <Button onClick={handleNextDialog}>Next</Button>}
                                            {dialogIndex === 1 && <Button onClick={handleNewAttendance}>Submit</Button>}
                                        </DialogActions>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </Dialog>



            {/* Sucess Snackbar */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={10000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {admin ? "Successfully added the attendance. Please refresh to see changes." : "Successfully attended the meeting. Thank you!"}
                </Alert>
            </Snackbar>

            {/* Error Snackbar */}
            <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={10000}
                onClose={handleCloseErrorSnackbar}
            >
                <Alert
                    onClose={handleCloseErrorSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {serverErrorMessage}
                </Alert>
            </Snackbar>


        </div>
    );
};

export default AddAttendanceComponent;
