import React, { useEffect, useState } from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

const MembersPage = () => {
    const navigate = useNavigate();

    const user = useSelector((state) => state.session.user);
    const session = useSelector((state) => state.session.value);

    if (!session || !user) {
        navigate('/home');
    }

    const [loaded, setLoaded] = useState(false);
    const [members, setMembers] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [insuranceType, setInsuranceType] = useState("N/A");
    const [memberSinceAvilable, setMemberSinceAvilable] = useState(true);
    const [memberInsuranceNumNotAvilable, setMemberInsuranceNumNotAvilable] = useState(false);

    //form data
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [insuranceID, setInsuranceID] = useState("");
    const [memberSince, setMemberSince] = useState("");

    const fetchAllMembers = async () => {
        // Fetch all members from the server

        const headers = {
            Authorization: `Bearer ${session}`
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/private/members`, {
            headers: headers,
            params: { action: 'getAllMembers', filter: 'all' }
        });
        setMembers(response.data.members);
        setLoaded(true);
    }

    const EditMemberButtonPress = (id) => {
        // Redirect to the edit member page
        console.log("Edit member button pressed for member with id: " + id);
    }

    const openAddMemeberDialog = () => {
        setOpenAddDialog(true);
    }

    const closeAddMemberDialog = () => {
        setOpenAddDialog(false);
        clearForm();
    }

    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setInsuranceID("");
        setMemberSince("");
        setErrorMessage("");
        setMemberInsuranceNumNotAvilable(false);
        setMemberSinceAvilable(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    const handleNewMemberSubmit = async () => {
        // Submit the new member to the server

        if (firstName === "" || firstName === null || firstName === undefined) {
            setErrorMessage("A first name is required");
            return;
        }
        if (lastName === "" || lastName === null || lastName === undefined) {
            setErrorMessage("A last name is required");
            return;
        }
        if (email === "" || email === null || email === undefined) {
            setErrorMessage("An email is required");
            return;
        }

        if (firstName.includes(" ")) {
            setErrorMessage("First name cannot contain spaces");
            return;
        } else if (lastName.includes(" ")) {
            setErrorMessage("Last name cannot contain spaces");
            return;
        }

        setErrorMessage("");

        let insurance_id = insuranceID;
        let member_since = memberSince;

        if (insuranceType === "N/A" || memberInsuranceNumNotAvilable || insuranceID === "" || insuranceID === null || insuranceID === undefined) {
            insurance_id = "N/A";
        }

        if (memberSinceAvilable === false || memberSince === "" || memberSince === null || memberSince === undefined) {
            member_since = "N/A";
        }

        // console.log("Submitting new member");
        // console.log(firstName);
        // console.log(lastName);
        // console.log(email);
        // console.log(insuranceType);
        // console.log(insurance_id);
        // console.log(member_since);

        const headers = {
            Authorization: `Bearer ${session}`
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/private/members`, {
                first_name: firstName,
                last_name: lastName,
                email: email,
                insurance_type: insuranceType,
                insurance_id: insurance_id,
                member_since: member_since,
                action: 'addMember'
            }, {
                headers: headers
            });

            setOpenSnackbar(true);
            setOpenAddDialog(false);
            clearForm();

            setTimeout(() => {
                fetchAllMembers();
            }, 500);

        } catch (error) {
            console.error('Failed to add new member:', error);
            setErrorMessage("Failed to add new member. Is the email unique? Contact Dev if the problem persists.");
            return;
        }
    }

    const handleSelectChange = (event) => {
        setInsuranceType(event.target.value);
    }

    const handleMemberSinceNotAvailable = (event) => {
        setMemberSinceAvilable(!memberSinceAvilable);
    }

    const handleMemberInsuranceNumNotAvilable = (event) => {
        setMemberInsuranceNumNotAvilable(!memberInsuranceNumNotAvilable);
    }


    useEffect(() => {
        fetchAllMembers();
    }, []);

    return (
        <div>
            <CustomAppBar title={"All Members of " + user.organization} displayLogin={true} displayMenu={true} displayHome={false} />



            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Item><p>When a member registers for this organization they will automatically be added to this list. All members are displayed (even from past seasons).</p></Item>
                </Grid>
                <Grid item xs={2}>
                    <Item><Button variant="contained" onClick={openAddMemeberDialog} endIcon={<AddIcon />}>
                        Add Member
                    </Button></Item>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingBottom: '16px' }}>
                {/* Content with bottom padding */}
            </Grid>

            {loaded ?
                <TableContainer component={Paper} sx={{ maxWidth: 1000, margin: '0 auto' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Name</b></TableCell>
                                <TableCell align="right"><b>Email</b></TableCell>
                                <TableCell align="right"><b>Insurance Type</b>
                                    <Tooltip disableFocusListener title={
                                        <p>Members can have any of the following insurances:<br />
                                            1. National Association of Sport Officials (NASO)<br />
                                            2. National Federation of High Schools Officials Association (NFHS)<br />
                                            3. Orange County Baseball Officials Association (OCBOA)<br />
                                            4. Amateur Baseball Umpires Association (ABUA)<br />
                                            5. Pony Baseball umpire w/insurance (PONY)<br />
                                            6. General Liability through another organization (GENERAL INSURANCE)<br />
                                            Note: This list can chnage and this tooltip might be outdated.</p>}>
                                        <HelpIcon />
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right"><b>Insurance ID</b></TableCell>
                                <TableCell align="right"><b>Member Since</b></TableCell>
                                <TableCell align="right"><b>Guest</b>
                                    <Tooltip disableFocusListener title="A member is a guest if they attended a meeting from this organization and was not added to this system prior. No record of them existed beforehand.">
                                        <HelpIcon />
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {members.map((member) => (
                                <TableRow
                                    key={member._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {member.first_name.charAt(0).toUpperCase() + member.first_name.slice(1)} {member.last_name.charAt(0).toUpperCase() + member.last_name.slice(1)}
                                    </TableCell>
                                    <TableCell align="right">{member.email}</TableCell>
                                    <TableCell align="right">{member.insurance_type}</TableCell>
                                    <TableCell align="right">{member.insurance_id}</TableCell>
                                    <TableCell align="right">{member.member_since.split("T")[0]}</TableCell>
                                    <TableCell align="right">{member.guest ? "Yes" : "No"}</TableCell>
                                    <TableCell align="right"><Button disabled variant="outlined" onClick={() => EditMemberButtonPress(member._id)}>Edit</Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            }

            {members.length === 0 && loaded && <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}><em>No members exist for this organization</em></h3>}


            <Dialog open={openAddDialog} onClose={closeAddMemberDialog}>
                <DialogTitle>Add New Member</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill out the form below to add a new member manually to the organization.
                    </DialogContentText>

                    <Grid container spacing={2} style={{ marginBottom: '25px' }}>
                        <Grid item xs={4}>
                            <Item>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="first_name"
                                    label="First Name"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item>
                                <TextField
                                    margin="dense"
                                    id="last_name"
                                    label="Last Name"
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Item>
                        </Grid>
                    </Grid>

                    <TextField
                        margin="dense"
                        id="email"
                        label="Email Address"
                        fullWidth
                        style={{ marginBottom: '30px' }}
                        variant="standard"
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <Grid container spacing={4} style={{ marginBottom: '20px' }}>
                        <Grid item xs={4}>
                            <Item><h4>Insurance Type:</h4></Item>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ m: 1, minWidth: 100, maxWidth: 275 }} >
                                <InputLabel id="demo-simple-select-label">Insurance</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={insuranceType}
                                    label="Insurance"
                                    onChange={handleSelectChange}

                                >
                                    <MenuItem value={"NASO"}>National Association of Sport Officials (NASO)</MenuItem>
                                    <MenuItem value={"NFHS"}>National Federation of High Schools Officials Association (NFHS)</MenuItem>
                                    <MenuItem value={"OCBOA"}>Orange County Baseball Officials Association (OCBOA)</MenuItem>
                                    <MenuItem value={"ABUA"}>Amateur Baseball Umpires Association (ABUA)</MenuItem>
                                    <MenuItem value={"PONY"}>Pony Baseball umpire w/insurance</MenuItem>
                                    <MenuItem value={"GENERAL INSURANCE"}>General Liability through another organization</MenuItem>
                                    <MenuItem value={"N/A"}>Not Available</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} style={{ marginBottom: '16px' }}>
                        <Grid item xs={4}>
                            <Item><h4 style={{ color: (insuranceType === "N/A" || memberInsuranceNumNotAvilable) ? '#d6d6d6' : 'inherit' }}>Insurance Number: </h4></Item>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                disabled={insuranceType === "N/A" || memberInsuranceNumNotAvilable}
                                margin="dense"
                                id="id"
                                label={(insuranceType === "N/A" || memberInsuranceNumNotAvilable) ? "N/A" : "Insurance ID"}
                                fullWidth
                                variant="standard"
                                style={{ marginBottom: '16px' }}
                                onChange={(e) => setInsuranceID(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox />} onChange={handleMemberInsuranceNumNotAvilable} label="Not Available" style={{ marginTop: '13px' }} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} style={{ marginBottom: '16px' }}>
                        <Grid item xs={4}>
                            <Item><h4 style={{ color: memberSinceAvilable === false ? '#d6d6d6' : 'inherit' }}>Member Since: </h4></Item>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                disabled={memberSinceAvilable === false}
                                margin="dense"
                                id="id"
                                label={memberSinceAvilable === false ? "N/A" : "YEAR"}
                                fullWidth
                                variant="standard"
                                style={{ marginBottom: '16px' }}
                                onChange={(e) => setMemberSince(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox />} onChange={handleMemberSinceNotAvailable} label="Not Available" style={{ marginTop: '13px' }} />
                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <p style={{ color: 'red' }}>{errorMessage}</p>
                    <Button onClick={handleNewMemberSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>


            <Snackbar
                open={openSnackbar}
                autoHideDuration={10000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    New Member Added Successfully!
                </Alert>
            </Snackbar>

            <h3>Total count: {members.length}</h3>

        </div>
    );
};

export default MembersPage;