import React from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import styled from '@mui/material/styles/styled';
import AddAttendanceComponent from '../../components/public/AddAttendanceComponent';
import LockIcon from '@mui/icons-material/Lock';

const AttendancePage = () => {

    const [loaded, setLoaded] = useState(false);
    const [seletedOrganization, setSeletedOrganization] = useState(false);
    const [meetings, setMeetings] = useState([]);
    const [openAttendDialog, setOpenAttendDialog] = React.useState(false);
    const [selectedMeeting, setSelectedMeeting] = useState({});

    const MeetingPaper = styled(Paper)(({ theme }) => ({
        width: 200,
        padding: theme.spacing(2),
        ...theme.typography.body2,
        textAlign: 'center',
    }));


    const setOrganizationToCBUA = () => {
        selectOrganizationEvent('CBUA-LB');
    }

    const setOrganizationToOCBOA = () => {
        selectOrganizationEvent('OCBOA');
    }

    const selectOrganizationEvent = (organization) => {
        setSeletedOrganization(true);
        fetchOpenMeetings(organization);
    }

    const openAttendanceDialog = (meetingIndex) => {
        setSelectedMeeting(meetings[meetingIndex]);
        setOpenAttendDialog(true);
    }


    const fetchOpenMeetings = async (organization) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/meetings`, {
                params: { action: 'getOpenMeetings', organization: organization }
            });

            if (response.data.length === 0) {
                setLoaded(false);
            } else {
                setMeetings(response.data.meetings);
                setLoaded(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <CustomAppBar title="Attendance" displayLogin={false} displayMenu={false} displayHome={true} />

            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                {!seletedOrganization && (
                    <Button onClick={setOrganizationToCBUA} variant="contained" style={{ fontSize: '1.2rem' }}>CBUA-Long Beach Unit</Button>
                )}
            </div>
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                {/* {!seletedOrganization && (
                    <Button disabled onClick={setOrganizationToOCBOA} variant="contained" style={{ fontSize: '1.2rem' }}>OCBOA</Button>
                )} */}
            </div>
            {(loaded && seletedOrganization && meetings.length > 0) ? (
                <div>
                    <h2>Current Active Meetings:</h2>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {Array.from(Array(meetings.length)).map((_, index) => (
                            <Grid item xs={3} sm={3} md={3} key={index}>
                                <Item>
                                    <MeetingPaper square={false} height={275} style={{ backgroundColor: '#e5ffe5' }}>
                                        <h3>{meetings[index].meeting_name}</h3>
                                        <Divider />
                                        <p>{meetings[index].meeting_description}</p>
                                        <Divider />
                                        <p>{meetings[index].meeting_date.split("T")[0]}</p>
                                        <p>{meetings[index].meeting_type}</p>
                                        <p>{meetings[index].hours} hours</p>
                                        <Button startIcon={meetings[index].password_authenticated ? <LockIcon /> : null} variant="contained" href="#outlined-buttons" onClick={() => openAttendanceDialog(index)}>
                                            Attend
                                        </Button>
                                    </MeetingPaper>
                                </Item>
                            </Grid>
                        ))}
                    </Grid>

                    <AddAttendanceComponent admin={false} session={null} openAttendDialog={openAttendDialog} setOpenAttendDialog={setOpenAttendDialog} meeting={selectedMeeting} />

                </div>
            ) : (
                <div>
                    {seletedOrganization &&
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            {meetings.length === 0 && <h1>No active meetings found.</h1>}
                            {meetings.length !== 0 && <CircularProgress />}
                        </div>

                    }
                </div>
            )}
        </div>
    );
};

export default AttendancePage;
