import React from 'react';
import Login from './pages/public/LoginPage'; // Import the Login component
import HomePage from './pages/public/HomePage';
import RegistrationPage from './pages/public/RegistrationPage';
import AttendancePage from './pages/public/AttendancePage';
import RecordsPage from './pages/public/RecordsPage';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashboardPage from './pages/admin/DashboardPage';
import NotFoundPage from './pages/public/NotFoundPage';
import { useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { recoverToken, removeToken, setUser } from './reducers/sessionSlice';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MemberVerificationPage from './pages/admin/MembershipVerificationPage';
import MeetingsPage from './pages/admin/MeetingsPage';
import MembersPage from './pages/admin/MembersPage';
import ViewMeetingPage from './pages/admin/ViewMeetingPage';
import RegistrationAndPaymentsPage from './pages/admin/RegistrationAndPaymentsPage';
import VersionHistory from './pages/public/VersionHistory';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

//https://stackoverflow.com/questions/72101695/creating-a-new-theme-with-material-ui-version-5-with-react-version-18-01-error
const theme = createTheme({
  palette: {
    primary: {
      type: 'ligth',
      main: '#fefefe',
    },
    secondary: {
      main: '#f50057',
    },

  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeigthLigth: 400,
    fontWeigthRegular: 500,
    fontWeigthMedium: 600,
    fontWeigthBold: 700,
  }

})


function App() {

  const session = useSelector((state) => state.session.value);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  //fixes Warning: Cannot update a component (`App`) while rendering a different component (`App`). To locate the bad setState() call inside `App`, follow the stack trace as described
  useEffect(() => {
    dispatch(recoverToken());

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1 * 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        if (session === null) {   //fix bug: if user refreshes page, session is null because we kept clearing the session. 
          return;
        }
        //console.log("checking auth status session = " + session);
        const config = {
          headers: {
            Authorization: `Bearer ${session}` // Include the JWT in the Authorization header
          }
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/check-auth`, config);

        //console.log(response.data);

        if (!response.data.isLoggedIn) { //token valid
          dispatch(removeToken());
        } else {
          // console.log("token valid");
          dispatch(setUser(response.data.user));
        }

      } catch (error) {
        console.error('Failed to check auth status:', error);
      }
    };
    checkAuthStatus();
  }, [session]);

  return (
    <ThemeProvider theme={theme}>
      {isLoading ?
        <div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        </div>
        :
        <Router>
          <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/attendance" element={<AttendancePage />} />
            <Route path="/records" element={<RecordsPage />} />
            <Route path="/version-history" element={<VersionHistory />} />
            {session && (
              <>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/members" element={<MembersPage />} />
                <Route path="/meetings-and-attendance" element={<MeetingsPage />} />
                <Route path="/membership-verification" element={<MemberVerificationPage />} />
                <Route path="/registration-and-payments" element={<RegistrationAndPaymentsPage />} />
                <Route path="/view-meeting" element={<ViewMeetingPage />} />
              </>
            )}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      }


    </ThemeProvider>
  );
}

export default App;
