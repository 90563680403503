import React from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import AtAGlanceComponent from '../../components/admin/AtAGlanceComponent';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

function DashboardPage() {

    const user = useSelector((state) => state.session.user);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#FAEBD2' : '#FAEBD2',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    return (
        <div>
            <CustomAppBar title={`Dashboard for ${user ? user.organization : "..."} unit`} displayLogin={true} displayMenu={true} displayHome={false} />
            <h1>Welcome back{user ? `, ${user.first_name}.` : "."}</h1>
            <Alert severity="info">A lot of updates are coming to this web application. Please check your emails for software updates.</Alert>
            <br></br>


            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Item><AtAGlanceComponent /></Item>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <Item>Coming soon.</Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>Coming soon.</Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>Coming soon.</Item>
                    </Grid> */}
                </Grid>
            </Box>

        </div>
    );
}

export default DashboardPage;
