import React from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

const RecordsPage = () => {

    const [validRequest, setValidRequest] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [textFieldData, setTextFieldData] = useState('');
    const [member, setMember] = useState({});

    const onSearchBtnPress = async () => {

        if (textFieldData === '' || (textFieldData.indexOf('@') === -1 && textFieldData.split(' ').length < 2)) {
            setErrorMessage('Please enter a valid name (first & last name) or email');
            return;
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/public/membership`, {
                params: { action: 'getMembershipDetails', data: textFieldData }
            });

            if (response.data.status === 'error') {
                setErrorMessage("The membership record could not be found. Please try again.");
                return;
            }
            setMember(response.data.member);
            setValidRequest(true);
            setErrorMessage('');
        } catch (error) {
            setErrorMessage("The membership record could not be found. Please try again.");
        }

    }

    return (
        <div>
            <CustomAppBar title="Memeber Records & Details" displayLogin={false} displayMenu={false} displayHome={true} />

            {!validRequest &&
                <div style={{
                    position: 'absolute',
                    top: '25%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    borderRadius: '5px'
                }}>
                    <h1>Search your Membership/Guest Information</h1>


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                id="outlined-basic"
                                label="Full Name or Email"
                                variant="outlined"
                                fullWidth
                                value={textFieldData}
                                onChange={(e) => setTextFieldData(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                startIcon={<SearchIcon />}
                                variant="contained"
                                fullWidth
                                onClick={onSearchBtnPress}
                                style={{ height: '55px' }}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                    <h3 style={{ color: 'red', fontSize: '18px' }}>{errorMessage}</h3>

                </div >
            }

            {
                validRequest &&
                <div style={{
                    position: 'relative', // Changed to relative positioning
                    padding: '20px',
                    borderRadius: '5px',
                    textAlign: 'center', // Center aligning the content
                    maxWidth: '90%', // Limiting the width to avoid overflow on small screens
                    margin: '0 auto' // Center aligning the container itself
                }}>
                    <h1>Member Records</h1>
                    {member.first_name && <p style={{ fontSize: '20px' }}><b>Name:</b> {member.first_name.charAt(0).toUpperCase() + member.first_name.slice(1)} {member.last_name.charAt(0).toUpperCase() + member.last_name.slice(1)}</p>
                    }
                    <p style={{ fontSize: '20px' }}><b>Meetings Attended:</b> {member.meetings_attended}</p>
                    <p style={{ fontSize: '20px' }}><b>Meeting Hours:</b> {member.hours}</p>
                    <p style={{ fontSize: '20px' }}><b>Name of Insurance:</b> {member.insurance_type}</p>
                    <p style={{ fontSize: '20px' }}><b>Registered:</b> {member.registered ? "Yes" : "No"}</p>
                    <p style={{ fontSize: '20px' }}><b>Year Joined:</b> {member.member_since}</p>
                    {member.guest && <p style={{ fontSize: '20px' }}><b>Guest:</b> Yes <Tooltip disableFocusListener title={
                        <p>A guest is a member who attended a meeting and is not officially registered with this organization</p>}>
                        <HelpIcon />
                    </Tooltip></p>}

                    <br />
                    <p>Notes:<br />
                        18 instructional hours, including a field clinic, are required to remain a member in good standing.<br />
                        Instructional Meetings: 2 hrs<br />
                        Field Clinics: 3 hrs<br />
                        Study Guide: 3 hrs<br />
                        Qualification Exam: 2 hrs<br />
                        At least ten (10) hours of instruction must be attended at the Long Beach Unit.<br />
                        Meetings missed at Long Beach will have to be made up at another unit.<br />
                        A Field Clinic is required for High School Umpire Certification and to qualify for playoff assignments.<br />
                        The Field Clinic requirement may be met by attending either a CBUA Regional Clinic, the Long Beach Unit Field Clinic or another Units authorized Field Clinic. Other options may also be accepted. Please check with the Instructional Chair beforehand.</p>
                </div>
            }

        </div >
    );
};

export default RecordsPage;
