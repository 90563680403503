import React from 'react';
import CustomAppBar from '../../components/CustomAppBar';

const NotFoundPage = () => {
    return (
        <div>
            <CustomAppBar title="Error 404: Not Found" displayLogin={false} displayMenu={false} displayHome={true}/>
            <p>The page you are looking for does not exist. If it use to exist, perhaps try logging back in.</p>
        </div>
    );
};

export default NotFoundPage;
