import { React, useContext } from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import Button from '@mui/material/Button';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import TaskIcon from '@mui/icons-material/Task';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';

const HomePage = () => {

    const navigate = useNavigate();

    const openMembership = () => {
        navigate("/registration");
    };

    const openAttendance = () => {
        navigate("/attendance");
    };

    const openRecords = () => {
        navigate("/records");
    };

    const navigateToVersionHistory = () => {
        navigate("/version-history");
    };

    return (
        <div>
            <CustomAppBar title="Welcome to the Umpire Org. Web App" displayLogin={true} displayMenu={false} />

            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                {/* <Button
                    startIcon={<HowToRegIcon />}
                    onClick={openMembership}
                    variant="outlined"
                    style={{ fontSize: '1.2rem' }}
                    disabled={true}
                >
                    Membership Registration or Renewal (Coming Soon)
                </Button> */}
            </div>
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                <Button startIcon={<TaskIcon />} onClick={openAttendance} variant="outlined" style={{ fontSize: '1.2rem' }}>Attendance</Button>
            </div>
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                <Button startIcon={<ManageSearchIcon />} onClick={openRecords} variant="outlined" style={{ fontSize: '1.2rem' }}>Membership Information</Button>
            </div>

            {/* FOR TESTING REDUX: */}
            {/* <Counter /> */}
            <div style={{ position: 'absolute', bottom: '0', width: '100%', textAlign: 'center' }}>
                <p></p>
                <Link style={{ cursor: 'pointer' }} onClick={navigateToVersionHistory}><em>Version 1.1</em></Link>
            </div>
        </div>
    );
};

export default HomePage;