import React, { useState } from 'react';
import { Button, TextField, Alert } from '@mui/material';
import axios from 'axios';
import CustomAppBar from '../../components/CustomAppBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken, setUser } from '../../reducers/sessionSlice';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [missingLoginInput, setmissingLoginInput] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [loginError, setloginError] = useState(false);
    const dispatch = useDispatch()

    const handleLogin = async () => {

        setForgotPassword(false);

        try {
            if (email === '' || password === '') {
                console.error('email or password cannot be empty');
                setmissingLoginInput(true);
                return;
            }
            setmissingLoginInput(false);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, {
                email,
                password
            });

            // if wanting to work with cookies.
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, {
            //     email,
            //     password
            // }, { withCredentials: true });

            //console.log(response.data);
            dispatch(setToken(response.data.token));
            dispatch(setUser(response.data.user));
            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed:', error);
            setloginError(true);
        }
    };

    const handleForgotPassword = async () => {
        setForgotPassword(true);
    };

    return (
        <div>
            <CustomAppBar title="Login" displayLogOut={false} displayMenu={false} displayHome={true} />

            {missingLoginInput && (
                <Alert severity="warning">
                    Email or password cannot be empty. Please try again.
                </Alert>

            )}

            {loginError && <Alert style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }} severity="error">Incorrect email or password provided. Please check your input.</Alert>}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 300,
                        height: 400,
                    },
                }}
            >
                <Paper elevation={3}>
                    <SportsBaseballIcon sx={{ fontSize: 100, margin: 'auto' }} />

                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ margin: 'auto', marginTop: 20 }}
                    />

                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ margin: 'auto', marginTop: 20 }}
                    />
                    <br></br>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                        style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }}
                    >
                        Login
                    </Button>
                    <br></br>
                    <Chip label="Forgot Password" variant="outlined" onClick={handleForgotPassword} />

                </Paper>
            </Box>
            {forgotPassword && <Alert style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }} severity="warning">You must contact your tech officer if you have forgotten your password.</Alert>}

        </div>
    );
};

export default Login;
