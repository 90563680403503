import React from 'react';
import CustomAppBar from '../../components/CustomAppBar';

const MembershipPage = () => {
    return (
        <div>
            <CustomAppBar title="Memebership Registration" displayLogin={false} displayMenu={false} displayHome={true}/>
        </div>
    );
};

export default MembershipPage;
