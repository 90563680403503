import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomAppBar from '../../components/CustomAppBar';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddAttendanceComponent from '../../components/public/AddAttendanceComponent';
import Link from '@mui/material/Link';

function ViewMeetingPage() {

    const session = useSelector((state) => state.session.value);

    let location = useLocation();
    let meeting_id = location.state.meeting_id;

    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [meeting, setMeeting] = useState({});
    const [openSnackbar, setSnackbarOpen] = React.useState(false);
    const [attendance, setAttendance] = useState([{}])


    // handling the view other orgs that need proof of attendance
    const [selectedMember, setSelectedMember] = useState('null')
    const [listOfOtherOrgs, setListOfOtherOrgs] = useState([])
    const [openListOfOtherOrgsDialog, setopenListOfOtherOrgsDialog] = useState(false);

    //handle new attendance
    const [openAttendDialog, setOpenAttendDialog] = React.useState(false);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const getMeetingAttendance = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${session}`
            };

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/attendance`, {
                headers: headers,
                params: { action: 'getMemberAttendance', meeting_id: meeting_id }
            });

            const formattedData = response.data.map(member => {
                member.first_name = member.first_name.charAt(0).toUpperCase() + member.first_name.slice(1);
                member.last_name = member.last_name.charAt(0).toUpperCase() + member.last_name.slice(1);
                return member;
            });

            setAttendance(formattedData);
        } catch (error) {
            console.error(error);
        }
    }


    const onProofNeededButtonClick = (member_id) => {
        for (let i = 0; i < attendance.length; i++) {
            if (attendance[i]._id === member_id) {
                const member = attendance[i];
                setListOfOtherOrgs(member.proof_needed.split(","));
                setSelectedMember(member.first_name + " " + member.last_name);
            }
        }
        setopenListOfOtherOrgsDialog(true);
    }

    const handleCloseListOfOtherOrgsDialog = () => {
        setopenListOfOtherOrgsDialog(false);
    }


    const deleteMeeting = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${session}`
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/admin/meetings`,
                { action: 'deleteMeeting', meeting_id: meeting_id },
                { headers: headers },
            );

            if (response.data.success) {
                navigate("/meetings-and-attendance");
            }

        } catch (error) {
            console.error(error);
        }
    }


    const handleActiveChange = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${session}`
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/admin/meetings`,
                { action: 'toggleActive', meeting_id: meeting_id },
                { headers: headers },
            );

            if (response.data.success) {
                setMeeting({ ...meeting, active: !meeting.active });

            }
        } catch (error) {
            console.error(error);
        }
    }

    const fetchMeetingInformation = async () => {
        try {

            const headers = {
                Authorization: `Bearer ${session}`
            };
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/meetings`, {
                headers: headers,
                params: { action: 'getMeetingById', meeting_id: meeting_id }
            });

            setMeeting(response.data.meeting);

            setLoaded(true);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchMeetingInformation();
        getMeetingAttendance();
    }, []);

    const returnToMeetings = () => {
        navigate("/meetings-and-attendance");
    };

    const addNewAttendant = () => {
        setOpenAttendDialog(true);
    }


    return (
        <div>
            <CustomAppBar title="View/Edit Meeting" displayLogin={true} displayMenu={true} displayHome={false} />

            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Item>    <Button variant="outlined" onClick={returnToMeetings} startIcon={<ArrowBackIcon />}>
                        Back
                    </Button></Item>
                </Grid>
                <Grid item xs={2}>
                    <Item> <Button
                        disabled
                        color="error"
                        variant="outlined"
                        onClick={deleteMeeting}
                        startIcon={<DeleteIcon />}
                    >
                        Delete Meeting
                    </Button></Item>
                </Grid>
            </Grid>

            {loaded ?
                <div>
                    <br></br>
                    <h3>Name: {meeting.meeting_name}</h3>
                    <h3>Description: {meeting.meeting_description && meeting.meeting_description.length > 0 ? meeting.meeting_description : "N/A"}</h3>
                    <h3>Date: {meeting.meeting_date.split("T")[0]}</h3>
                    <h3>Type: {meeting.meeting_type}</h3>
                    <h3>Hours: {meeting.hours}</h3>
                    <h3>Password: {meeting.password && meeting.password.length > 0 ? meeting.password : "N/A"}</h3>
                    <h3>Active: <Switch checked={meeting.active} onClick={handleActiveChange} /></h3>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {attendance.length > 0 ?
                            <TableContainer component={Paper} sx={{ maxWidth: 600, margin: '0 auto' }}>
                                <Grid container>
                                    <Grid item xs={9}>
                                        <Item> <h2>Attendance:</h2></Item>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Item>
                                            <Button variant="contained" startIcon={<PersonAddIcon />} onClick={addNewAttendant} style={{ marginTop: '10px' }}>Attendance</Button>
                                        </Item>
                                    </Grid>
                                </Grid>

                                <Table sx={{ minWidth: 150 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>First Name</b></TableCell>
                                            <TableCell align="right"><b>Last Name</b></TableCell>
                                            <TableCell align="right"><b>Proof Needed</b></TableCell>
                                            <TableCell align="right"><b>Submission Date</b></TableCell>
                                            {/* <TableCell align="right"><b>Action</b></TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attendance.map((member) => (
                                            <TableRow
                                                key={member._id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {member.first_name}
                                                </TableCell>
                                                <TableCell align="right">{member.last_name}</TableCell>
                                                <TableCell align="right">
                                                    {member.proof_needed !== "No" ? (
                                                        <Button variant="text" style={{ color: "red" }} onClick={() => onProofNeededButtonClick(member._id)}>
                                                            Yes
                                                        </Button>
                                                    ) : (
                                                        <Button variant="text" style={{ color: "black" }}>
                                                            No
                                                        </Button>
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">{member.submitted !== undefined ? member.submitted.split("T")[0] : "Loading..."}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <div>
                                <p>No attendance for this meeting as of yet

                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => addNewAttendant()}
                                    >
                                        , but you may add a new attendant by clicking here.
                                    </Link>

                                </p>
                            </div>
                        }

                    </div>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={10000}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert
                            onClose={handleCloseSnackbar}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            Successfully updated meeting active state to {meeting.active ? "active" : "inactive"}.
                        </Alert>
                    </Snackbar>

                    {/* list of orgs that need proof of attendance dialog*/}
                    <Dialog onClose={handleCloseListOfOtherOrgsDialog} open={openListOfOtherOrgsDialog}>
                        <DialogTitle><b>{selectedMember}</b> Needs Attendance Proof For:</DialogTitle>
                        <List sx={{ pt: 0 }}>
                            {listOfOtherOrgs.map((otherOrgs) => (
                                <ListItem disableGutters key={otherOrgs} style={{ textAlign: 'center' }} sx={{ paddingLeft: '20px' }}>
                                    <div>
                                        <p>{otherOrgs}</p>
                                    </div>
                                </ListItem>
                            ))}
                        </List>
                    </Dialog>

                    <AddAttendanceComponent admin={true} session={session} openAttendDialog={openAttendDialog} setOpenAttendDialog={setOpenAttendDialog} meeting={meeting} />

                </div>
                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            }
        </div>
    );
}

export default ViewMeetingPage;
