import React, { useEffect } from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import { Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

const MeetingsPage = () => {

    const navigate = useNavigate();
    const session = useSelector((state) => state.session.value);

    const [loaded, setLoaded] = useState(true);
    const [activeMeeting, setActiveMeeting] = useState(true);
    const [meetings, setMeetings] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [meetingType, setmeetingType] = useState("MEETING");
    const [errorMessage, seterrorMessage] = useState("");
    const [date, setDate] = useState();
    const [hours, setHours] = useState(0);
    const [openSnackbar, setSnackbarOpen] = React.useState(false);

    const MeetingPaper = styled(Paper)(({ theme }) => ({
        width: 200,
        height: 275,
        padding: theme.spacing(2),
        ...theme.typography.body2,
        textAlign: 'center',
    }));

    const handleChange = (event) => {
        setmeetingType(event.target.value);
    };

    const handleActiveChange = () => {
        setActiveMeeting(!activeMeeting);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };


    const fetchMeetings = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${session}`
            };

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/meetings`, {
                headers: headers,
                params: { action: 'getAllMeetings' }
            });

            setMeetings(response.data.meetingList);
            setLoaded(true);
        } catch (error) {
            console.error('Meeting fetch failed:', error);
        }
    };

    const openAddMeetingDialog = () => {
        setOpenDialog(true);
    };

    const loadTestMeetings = () => {
        setMeetings([
            {
                "_id": "659a25c443ebaea452fbb57d",
                "meeting_name": "Meeting 1 - This is the longest meeting name ever. I wonder if it will fit?",
                "meeting_date": "2021-10-01",
                "meeting_type": "CLASSROOM",
                "hours": 3,
                "meetingAttendanceCount": 0,
                "active": true,

            },
            {
                "_id": "659a25c443ebaea452fbb57d",
                "meeting_name": "Meeting 2",
                "meeting_date": "2021-10-01",
                "meeting_type": "CLASSROOM",
                "hours": 3,
                "meetingAttendanceCount": 3,
                "active": false,

            },
            {
                "_id": "659a25c443ebaea452fbb57d",
                "meeting_name": "Meeting 3",
                "meeting_date": "2021-10-01",
                "meeting_type": "CLASSROOM",
                "hours": 5,
                "meetingAttendanceCount": 3,
                "active": true,

            }, {
                "_id": "659a25c443ebaea452fbb57d",
                "meeting_name": "Meeting 4",
                "meeting_date": "2021-10-01",
                "meeting_type": "CLASSROOM",
                "hours": 3,
                "meetingAttendanceCount": 3,
                "active": true,

            },
            {
                "_id": "659a25c443ebaea452fbb57d",
                "meeting_name": "Meeting 5",
                "meeting_date": "2021-10-01",
                "meeting_type": "CLASSROOM",
                "hours": 3,
                "meetingAttendanceCount": 3,
                "active": false,

            },
            {
                "_id": "659a25c443ebaea452fbb57d",
                "meeting_name": "Meeting 6",
                "meeting_date": "2021-10-01",
                "meeting_type": "CLASSROOM",
                "hours": 5,
                "meetingAttendanceCount": 3,
                "active": true,

            }, {
                "_id": "659a25c443ebaea452fbb57d",
                "meeting_name": "Meeting 7",
                "meeting_date": "2021-10-01",
                "meeting_type": "CLASSROOM",
                "hours": 5,
                "meetingAttendanceCount": 3,
                "active": false,

            },
        ]);
    };

    const incrementHours = () => {
        setHours(hours + 1);
    };

    const decrementHours = () => {
        if (hours > 0) {
            setHours(hours - 1);
        }
    };

    const handleNewMeetingSubmitBtnPress = async () => {
        //print out all the values:
        // console.log("meeting name: " + document.getElementById('name').value);
        // console.log("meeting description: " + document.getElementById('description').value);
        // console.log("meeting type: " + meetingType);
        // console.log("meeting date: " + dayjs(date).format('YYYY-MM-DD'));
        // console.log("meeting hours: " + hours);
        // console.log("active: " + activeMeeting);

        if (document.getElementById('name').value === "") {
            seterrorMessage("Meeting name cannot be empty.");
            return;
        }

        if (date === undefined) {
            seterrorMessage("Meeting date cannot be empty.");
            return;
        }

        const headers = {
            Authorization: `Bearer ${session}`
        };

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/admin/meetings`,
            {
                action: 'addMeeting', meeting: {
                    meeting_name: document.getElementById('name').value,
                    meeting_description: document.getElementById('description').value,
                    meeting_type: meetingType, meeting_date: dayjs(date).format('YYYY-MM-DD'),
                    hours: hours,
                    active: activeMeeting,
                    password: document.getElementById('password').value
                }
            },
            { headers: headers },
        );

        //clear out the values:
        clearValues();

        setSnackbarOpen(true);

        setTimeout(() => {
            fetchMeetings();
        }, 500);
    };

    const viewEditButtonPress = (index) => {
        navigate('/view-meeting', { state: { meeting_id: index } });
    };

    useEffect(() => {
        fetchMeetings();
        //loadTestMeetings();
    }, []);


    const handleClose = () => {
        setOpenDialog(false);
        clearValues();
    }

    const clearValues = () => {
        document.getElementById('name').value = "";
        document.getElementById('description').value = "";
        document.getElementById('password').value = "";
        setmeetingType("MEETING");
        setDate(new Date());
        setHours(0);
        setActiveMeeting(true);
        setOpenDialog(false);
        seterrorMessage("");
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomAppBar title="Meetings & Attendance" displayLogin={true} displayMenu={true} displayHome={false} />

            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Item><h2>All Active / Inactive Meetings:</h2></Item>
                </Grid>
                <Grid item xs={2}>
                    <Item><Button variant="contained" onClick={openAddMeetingDialog} endIcon={<AddIcon />}>
                        Add Meeting
                    </Button></Item>
                </Grid>
            </Grid>

            <br></br>

            {loaded ?
                <TableContainer component={Paper} sx={{ maxWidth: 1000, margin: '0 auto' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Meeting Name</b></TableCell>
                                <TableCell align="right"><b>Date</b></TableCell>
                                <TableCell align="right"><b>Type</b></TableCell>
                                <TableCell align="right"><b>Hours</b></TableCell>
                                <TableCell align="right"><b>Attendance</b></TableCell>
                                <TableCell align="right"><b>Active</b></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {meetings.map((meeting) => (
                                <TableRow
                                    key={meeting.meeting_name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {meeting.meeting_name}
                                    </TableCell>
                                    <TableCell align="right">{meeting.meeting_date.split('T')[0]}</TableCell>
                                    <TableCell align="right">{meeting.meeting_type}</TableCell>
                                    <TableCell align="right">{meeting.hours}</TableCell>
                                    <TableCell align="right">{meeting.meetingAttendanceCount}</TableCell>
                                    <TableCell align="right">{meeting.active === true ? "True" : "False"}</TableCell>
                                    <TableCell align="right"><Button variant="outlined" onClick={() => viewEditButtonPress(meeting._id)}>View/Edit</Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <CircularProgress />
            }

            {meetings.length === 0 && loaded && <h3><em>No meetings exist in our database. Please add a new meeting to see it here.</em></h3>}


            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>Add New Meeting</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        All meetings that are set to Active are immediately seen by members in the front page. Members can then submit their attendance for the active meeting.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Meeting Name"
                        fullWidth
                        variant="standard"
                        style={{ marginBottom: '16px' }}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Meeting Description (Optional)"
                        fullWidth
                        variant="standard"
                        style={{ marginBottom: '16px' }}
                    />
                    <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                        <Grid item xs={2}>

                        </Grid>
                        <Grid item xs={2}>
                            <Item><h4>Type: </h4></Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item>  <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={meetingType}
                                label="meetingType"
                                onChange={handleChange}
                            >
                                <MenuItem value={"MEETING"}>MEETING</MenuItem>
                                <MenuItem value={"VIRTUAL"}>VIRTUAL</MenuItem>
                                <MenuItem value={"CLASS"}>CLASS</MenuItem>
                                <MenuItem value={"TRAINING"}>TRAINING</MenuItem>
                                <MenuItem value={"MAKEUP"}>MAKEUP</MenuItem>
                            </Select></Item>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                        <Grid item xs={2}>

                        </Grid>
                        <Grid item xs={2}>
                            <Item><h4>Date: </h4></Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item>
                                {/* https://stackoverflow.com/questions/76035120/getting-typeerror-date-isbefore-is-not-a-function-while-using-material-ui-date */}
                                <DatePicker
                                    label="Date of Meeting" onChange={(newValue) => { setDate(newValue) }}
                                />
                            </Item>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                        <Grid item xs={2}>

                        </Grid>
                        <Grid item xs={2}>
                            <Item><h4>Hours: </h4></Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <IconButton aria-label="delete" size="small" onClick={decrementHours}>
                                            <RemoveIcon fontSize="inherit" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <h3>{hours}</h3>
                                    </Grid>
                                    <Grid item>
                                        <IconButton aria-label="delete" size="small" onClick={incrementHours}>
                                            <AddIcon fontSize="inherit" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ marginBottom: '16px' }}>
                        <Grid item xs={2}>

                        </Grid>
                        <Grid item xs={2}>
                            <Item><h4>Password: </h4></Item>
                        </Grid>
                        <Grid item xs={4}>
                            <Item>
                                <TextField
                                    margin="dense"
                                    id="password"
                                    fullWidth
                                    variant="standard"
                                    style={{ marginBottom: '16px' }}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip disableFocusListener title="Optional. Memebers will need to be infomed what this password is to successfully attend." style={{ marginTop: '10px' }}>
                                <HelpIcon />
                            </Tooltip>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <p style={{ color: 'red' }}>{errorMessage}</p>
                    <FormControlLabel control={<Switch defaultChecked onClick={handleActiveChange} />} label={`${activeMeeting ? "Active" : "Not Active"}`} />
                    <Button onClick={handleNewMeetingSubmitBtnPress}>Submit</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={10000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    New Meeting Added Successfully!
                </Alert>
            </Snackbar>

        </LocalizationProvider>
    );
};

export default MeetingsPage;