import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    value: null,
    user: null
  },
  reducers: {
    setToken: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.value = action.payload
      localStorage.setItem('token', action.payload);
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },
    removeToken: (state) => {
      state.value = null;
      localStorage.removeItem('token');
    },
    recoverToken: (state) => {    //fetch token from local storage and informs app that user is logged in
      
      let token = localStorage.getItem('token');
      if (token === null) {
        //console.log("state.value = null")
        state.value = null;
      } else {
        //console.log("state.value = token");
        //console.log(token);
        state.value = token;
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { setToken, removeToken, recoverToken, setUser} = sessionSlice.actions

export default sessionSlice.reducer