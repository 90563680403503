import React, { useState, useEffect } from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useSelector } from 'react-redux';


const AtAGlanceComponent = () => {

    const session = useSelector((state) => state.session.value);

    const [loadingPercentage, setloadingPercentage] = useState(0);
    const [totalMembers, setTotalMembers] = useState(0);
    const [currentSeasonMembers, setCurrentSeasonMembers] = useState(0);
    const [currentUnverifiedPaymentMembers, setCurrentUnverifiedPaymentMembers] = useState(0);
    const [currentSeason, setCurrentSeason] = useState("N/A");

    //this fixes the error too many re-renders (this calls the function only once) leave the array empty
    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    Authorization: `Bearer ${session}`
                };

                //safe to know that each request will correspond to the same index in the responses array.
                const requests = [
                    axios.get(`${process.env.REACT_APP_API_URL}/api/private/settings`, {
                        headers: headers,
                        params: { action: 'getSeason' }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}/api/private/members`, {
                        headers: headers,
                        params: { action: 'getTotalMemberCount' }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}/api/private/registration`, {
                        headers: headers,
                        params: { action: 'getCurrentRegisteredMembersCount' }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}/api/private/payments`, {
                        headers: headers,
                        params: { action: 'getUnverifedPaymentMemberCount' }
                    })
                ];

                const responses = await axios.all(requests);

                // Calculate the progress percentage
                const progress = (responses.length / requests.length) * 100;
                setloadingPercentage(progress);

                //console.log(responses);

                setCurrentSeason(responses[0].data.current_season);
                setTotalMembers(responses[1].data.count);
                setCurrentSeasonMembers(responses[2].data.count);
                setCurrentUnverifiedPaymentMembers(responses[3].data.unverifiedCount);

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {loadingPercentage !== 100 ?

                <div>
                    <CircularProgress />
                </div>
                :
                <div>
                    <h2 style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Overview</h2>
                    <Divider />
                    <CardContent>
                        <Typography sx={{ fontSize: 15 }} gutterBottom>
                            Current Season: <b>{currentSeason}</b>
                            <IconButton aria-label="info">
                                <Tooltip disableFocusListener title="This current season. if the 'Season' tab is not available, then this is set by the developer.">
                                    <HelpIcon />
                                </Tooltip>
                            </IconButton>
                        </Typography>
                        <Typography sx={{ fontSize: 15 }} gutterBottom>
                            Total Member Count: <b>{totalMembers}</b>
                            <IconButton aria-label="info">
                                <Tooltip disableFocusListener title="The total number of members this organization has records of.">
                                    <HelpIcon />
                                </Tooltip>
                            </IconButton>
                        </Typography>
                        <Typography sx={{ fontSize: 15 }} gutterBottom>
                            Current Season Members [Registered]: <b>{currentSeasonMembers}</b>
                            <IconButton aria-label="info">
                                <Tooltip disableFocusListener title="The number of members who have registered. Payment is not considered here">
                                    <HelpIcon />
                                </Tooltip>
                            </IconButton>
                        </Typography>
                        <Typography sx={{ fontSize: 15 }} gutterBottom>
                            Members Needing Payment Verification: <b>{currentUnverifiedPaymentMembers}</b>
                            <IconButton aria-label="info">
                                <Tooltip disableFocusListener title="These are members who have registred and have not been verified as payment recieved. Go to the 'Membership Verification' tab to verify payment.">
                                    <HelpIcon />
                                </Tooltip>
                            </IconButton>
                        </Typography>
                    </CardContent>
                    <CardActions>
                        {/* <Button size="small">Help</Button> */}
                    </CardActions>
                </div>
            }
        </div>

    );
};

export default AtAGlanceComponent;
