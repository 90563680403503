import React from 'react';
import CustomAppBar from '../../components/CustomAppBar';

const MemberVerificationPage = () => {
    return (
        <div>
            <CustomAppBar title="Membership Status Verification" displayLogin={true} displayMenu={true} displayHome={false} />
            <h1>Coming soon!</h1>
        </div>
    );
};

export default MemberVerificationPage;