import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Redux
import store from './app/store'
import { Provider } from 'react-redux'

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
root.render(<Provider store={store}><App /></Provider>);