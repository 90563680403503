import React, { useEffect, useState } from 'react';
import CustomAppBar from '../../components/CustomAppBar';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function RegistrationAndPaymentsPage() {

    const navigate = useNavigate();

    const user = useSelector((state) => state.session.user);
    const session = useSelector((state) => state.session.value);

    if (!session || !user) {
        navigate('/home');
    }

    const [loaded, setLoaded] = useState(false);
    const [members, setMembers] = useState([]);
    const [registrations, setRegistrations] = useState([]);
    const [payments, setPayments] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // dialog data
    const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogContent, setDialogContent] = React.useState("");

    //new payment dialog
    const [openNewPaymentDialog, setOpenNewPaymentDialog] = React.useState(false);
    const [paymentType, setPaymentType] = React.useState("OTHER");
    const [memberID, setMemberId] = React.useState("");

    const fetchData = async () => {
        // Fetch all members from the server

        const headers = {
            Authorization: `Bearer ${session}`
        };

        //safe to know that each request will correspond to the same index in the responses array.
        const requests = [
            axios.get(`${process.env.REACT_APP_API_URL}/api/private/members`, {
                headers: headers,
                params: { action: 'getAllMembers', filter: 'first_name,last_name,email' }
            }),
            axios.get(`${process.env.REACT_APP_API_URL}/api/private/registration`, {
                headers: headers,
                params: { action: 'getSeasonRegisteredMembers' }
            }),
            axios.get(`${process.env.REACT_APP_API_URL}/api/private/payments`, {
                headers: headers,
                params: { action: 'getSeasonPayments', filter: 'member_id,payment_date,verified,details,payment_method,payment_amount' }
            })
        ];
        const responses = await axios.all(requests);


        setPayments(responses[2].data.payments);
        setMembers(responses[0].data.members);
        setRegistrations(responses[1].data.registrations);
        setLoaded(true);
    }

    const ViewDetailsBtnPress = (id) => {
        // Redirect to the edit member page

        setDialogTitle("Member Registration & Payment Details for " + members.find(member => member._id === id).first_name.charAt(0).toUpperCase() + members.find(member => member._id === id).first_name.slice(1) + " " + members.find(member => member._id === id).last_name.charAt(0).toUpperCase() + members.find(member => member._id === id).last_name.slice(1));
        if (registrations.some(registration => registration.member_id === id)) {

            let registration = registrations.find(registration => registration.member_id === id);
            let payment = payments.find(payment => payment.member_id === id);

            if (payment !== undefined) {

                if (payment !== undefined && payment.verified) {

                    let dialogContent = `This member is registered for the current season and has paid (VERIFIED).
Member registered on: ${registration.date.split("T")[0]}
Member registration details: ${registration.details}
Payment Amount of ${payment.payment_amount} from payment method: ${payment.payment_method}
Payment received on ${payment.payment_date.split("T")[0]}
Payment details: ${payment.details}`;

                    dialogContent = dialogContent.split('\n').map((item, i) => <p key={i}>{item}</p>);

                    setDialogContent(dialogContent);
                } else if (payment !== undefined && !payment.verified) {

                    let dialogContent = `This member is registered for the current season and has paid (UNVERIFIED).` + "\n\n" + "Member registered on: " + registration.date.split("T")[0] + "\n" + "Member registration details: " + registration.details + "\n\n" + "Payment Amount of " + payment.payment_amount + " from payment method:  " + payment.payment_method + "\n" + "Payment recieved on " + payment.payment_date.split("T")[0] + "\n";

                    dialogContent = dialogContent.split('\n').map((item, i) => <p key={i}>{item}</p>);

                    setDialogContent(dialogContent);
                }
            } else {
                let dialogContent = "This member is registered for the current season but has not paid.\n\n" + "Member registered on: " + registration.date.split("T")[0] + "\n" + "Member registration details: " + registration.details + "\n\n" + "Please select the 'Payment Verified' checkbox to verify their payment.";

                dialogContent = dialogContent.split('\n').map((item, i) => <p key={i}>{item}</p>);

                setDialogContent(dialogContent);
            }

        } else {
            setDialogContent("This member is not registered for the current season. Pleae select the 'Registered' checkbox to register them.");
        }

        setOpenDetailsDialog(true);

    }

    const hadPaid = (id) => {
        if (payments.some(payment => payment.member_id === id)) {
            return payments.find(payment => payment.member_id === id).verified;
        } else {
            return false;
        }
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handlePaymentChange = async (event, member_id) => {
        const headers = {
            Authorization: `Bearer ${session}`
        };

        if (payments.some(payment => payment.member_id === member_id)) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/private/payments`,
                    { action: 'verifyPaymentChange', member_id: member_id, verified: event.target.checked },
                    { headers: headers },
                );

                setOpenSnackbar(true);
                setTimeout(() => {
                    fetchData();
                }, 500);
            } catch (error) {
                console.error(error);
            }
        } else {
            console.log("payment does not exist, launch payment setup dialog here.");
            setMemberId(member_id);
            setOpenNewPaymentDialog(true);
        }

    };

    const submitNewPayment = async () => {
        const headers = {
            Authorization: `Bearer ${session}`
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/private/payments`,
                { action: 'verifyPaymentChange', member_id: memberID, payment_method: paymentType },
                { headers: headers },
            );
            setOpenNewPaymentDialog(false);
            setOpenSnackbar(true);
            setTimeout(() => {
                fetchData();
            }, 500);

        } catch (error) {
            console.error(error);
        }
    };

    const handleRegisteredChange = async (event, member_id) => {
        const headers = {
            Authorization: `Bearer ${session}`
        };

        if (registrations.some(registration => registration.member_id === member_id)) {
            //delte the registration
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/private/registration`,
                    { action: 'removeRegistration', member_id: member_id },
                    { headers: headers },
                );

                setOpenSnackbar(true);
                setTimeout(() => {
                    fetchData();
                }, 500);
            } catch (error) {
                console.error(error);
            }
        } else {
            //add the registration
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/private/registration`,
                    { action: 'registerMemberbyAdmin', member_id: member_id },
                    { headers: headers },
                );

                setOpenSnackbar(true);
                setTimeout(() => {
                    fetchData();
                }, 500);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleDetailsDialogClose = () => {
        setOpenDetailsDialog(false);
    };

    const handleNewPaymentClose = () => {
        setOpenNewPaymentDialog(false);
    }

    const handlePaymentMethodChange = (event) => {
        setPaymentType(event.target.value);
    }

    return (
        <div>
            <CustomAppBar title={"Registration & Payments of " + user.organization} displayLogin={true} displayMenu={true} displayHome={false} />

            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Item><p>When a member registers for this organization for this current season they will have a check mark under the Registered column. This list contains all members in this organization (even if they did not register, so that admins can manually register them). To get a list of members who stated that they paid and need payment verification, then navigate to the Payment Verification page for that list.</p></Item>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingBottom: '16px' }}>
                {/* Content with bottom padding */}
            </Grid>

            {loaded ?
                <TableContainer component={Paper} sx={{ maxWidth: 1000, margin: '0 auto' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Name</b></TableCell>
                                <TableCell align="right"><b>Email</b></TableCell>
                                <TableCell align="right"><b>Registered</b></TableCell>
                                <TableCell align="right"><b>Payment Verified</b></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {members.map((member) => (
                                <TableRow
                                    key={member._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {member.first_name.charAt(0).toUpperCase() + member.first_name.slice(1)} {member.last_name.charAt(0).toUpperCase() + member.last_name.slice(1)}
                                    </TableCell>
                                    <TableCell align="right">{member.email}</TableCell>
                                    <TableCell align="right">
                                        <FormControlLabel
                                            control={<Checkbox onChange={(event) => handleRegisteredChange(event, member._id)} checked={registrations.some(registration => registration.member_id === member._id)} />}
                                        />
                                    </TableCell>
                                    <TableCell align="right"><FormControlLabel disabled={!registrations.some(registration => registration.member_id === member._id)} control={<Checkbox onChange={(event) => handlePaymentChange(event, member._id)} checked={hadPaid(member._id)} />} /></TableCell>
                                    <TableCell align="right"><Button variant="outlined" onClick={() => ViewDetailsBtnPress(member._id)}>Details</Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            }

            {members.length === 0 && loaded && <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}><em>No members exist for this organization</em></h3>}

            <Snackbar
                open={openSnackbar}
                autoHideDuration={10000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Updated successfully. Please wait for screen to refresh.
                </Alert>
            </Snackbar>


            {/* More details dialog */}
            <BootstrapDialog
                onClose={handleDetailsDialogClose}
                aria-labelledby="customized-dialog-title"
                open={openDetailsDialog}
            >
                <DialogTitle sx={{ m: 3, p: 2 }} id="customized-dialog-title">
                    {dialogTitle}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleDetailsDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    {dialogContent}
                </DialogContent>
            </BootstrapDialog>

            {/* New payment dialog */}
            <Dialog
                open={openNewPaymentDialog}
                onClose={handleNewPaymentClose}
            >
                <DialogTitle>Add New Payment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        No recorded payment found for this member on this system. If a payment was recieved, please provide the following details to record the payment for this season.
                    </DialogContentText>
                    <br></br>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={paymentType}
                            label="Payment Method"
                            onChange={handlePaymentMethodChange}
                        >
                            <MenuItem value={"PAYPAL"}>PayPal</MenuItem>
                            <MenuItem value={"VENMO"}>Venmo</MenuItem>
                            <MenuItem value={"ZELLE"}>Zelle</MenuItem>
                            <MenuItem value={"CASH"}>Cash</MenuItem>
                            <MenuItem value={"CHECK"}>Check</MenuItem>
                            <MenuItem value={"OTHER"}>Other</MenuItem>
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNewPaymentClose}>Cancel</Button>
                    <Button onClick={submitNewPayment}>Submit</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default RegistrationAndPaymentsPage;
